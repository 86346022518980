import { showNotification } from '@mantine/notifications'
import { MoipCreditCard } from 'moip-sdk-js'

// eslint-disable-next-line no-unused-vars
async function wirecardInstance({ number, cvc, expirationMonth, expirationYear }) {
  // dynamic import external lib
  const jsencrypt = typeof window !== 'undefined' ? (await import('jsencrypt')).default : null

  const decodedKey =
    typeof window !== 'undefined'
      ? window.atob(process.env.NEXT_PUBLIC_WIRECARD_B64_PUBLIC_KEY)
      : ''

  return MoipCreditCard.setEncrypter(jsencrypt, 'js')
    .setPubKey(decodedKey)
    .setCreditCard({ number, cvc, expirationMonth, expirationYear })
    .hash()
    .then(hash => ({ hash }))
}

async function pagSeguroInstance(
  { holder, number, cvc, expMonth, expYear },
  paymentGateway,
  isBackup
) {
  if (typeof window === 'undefined') return null

  const card = await window.PagSeguro?.encryptCard({
    publicKey: process.env.NEXT_PUBLIC_PAGSEGURO_B64_PUBLIC_KEY,
    holder,
    number,
    expMonth,
    expYear: `20${expYear}`,
    securityCode: cvc
  })

  if (!card) {
    throw new Error(
      'Houve um erro durante o cadastro pelo Gateway. Atualize a página e tente novamente.'
    )
  }

  if (card?.hasErrors && !isBackup) {
    const errorMessage = {
      INVALID_NUMBER: 'Número de cartão inválido',
      INVALID_SECURITY_CODE: 'Código de segurança do cartão inválido',
      INVALID_EXPIRATION_MONTH: 'Mês inválido',
      INVALID_EXPIRATION_YEAR: 'Ano inválido',
      INVALID_HOLDER: 'Nome inválido'
    }
    const message = errorMessage[card?.errors?.[0]?.code] || JSON.stringify(card?.errors || card)
    throw new Error(message)
  }

  return { hash: card.encryptedCard || null }
}

async function iuguInstance({ holder, number, cvc, expMonth, expYear }, paymentGateway, isBackup) {
  if (typeof window === 'undefined') return null

  const { clientConfig } = paymentGateway || {}

  const isTest =
    clientConfig?.env === 'test' ||
    !(!!process.env.NEXT_PUBLIC_VERCEL_ENV && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production')

  const Iugu = window.Iugu

  await Iugu?.setAccountID(clientConfig?.accountId || process.env.NEXT_PUBLIC_IUGU_ACCOUNT_ID)
  await Iugu?.setTestMode(isTest)

  const nameArray = typeof holder === 'string' && !!holder.length ? holder.split(' ') : []
  const [first, ...last] = nameArray
  const firstName = first || ''
  const lastName = Array.isArray(last) && !!last?.length ? last.join(' ') : ''

  const card = await Iugu?.CreditCard(
    `${number}`,
    `${expMonth}`,
    `${expYear}`,
    firstName,
    lastName,
    `${cvc}`
  )

  return new Promise((resolve, reject) =>
    Iugu?.createPaymentToken(card, response => {
      if (response.errors && !isBackup) {
        const errorMessage = {
          number: 'Número de cartão inválido',
          record_invalid: 'Número de cartão inválido',
          verification_value: 'Código de segurança do cartão inválido',
          expiration: 'Mês inválido',
          expiration_month: 'Mês inválido',
          expiration_year: 'Ano inválido',
          first_name: 'Nome inválido',
          last_name: 'Nome inválido',
          full_name: ' Nome inválido'
        }

        const message =
          errorMessage[Object.keys(response.errors)?.[0]] ||
          JSON.stringify(response?.errors || response)
        if (isTest && !!response.errors?.record_invalid) {
          reject(message)
        } else throw new Error(message)
      } else resolve({ hash: response.id || null })
    })
  )
}

export async function cryptoToolkit(payload, option) {
  const { holder, number, cvc, expYear, expMonth } = payload

  const { paymentGateway, backupPaymentGateways = [] } = option || {}

  const pagSeguroBackup = backupPaymentGateways?.find(gateway => gateway.code === 'pagseguro')
  const iuguBackup = backupPaymentGateways?.find(gateway => gateway.code === 'iugu')

  const hasPagSeguro = paymentGateway?.code === 'pagseguro' || !!pagSeguroBackup
  const hasIugu = paymentGateway?.code === 'iugu' || !!iuguBackup

  const pagseguro = hasPagSeguro
    ? await pagSeguroInstance(
      { holder, cvc, number, expMonth, expYear },
      pagSeguroBackup || paymentGateway,
      !!pagSeguroBackup
    )
    : null

  const iugu = hasIugu
    ? await iuguInstance(
      { holder, cvc, number, expMonth, expYear },
      iuguBackup || paymentGateway,
      !!iuguBackup
    )
    : null

  return { pagseguro, iugu, creditCard: payload }
}

export const errorHandler = error => {
  const { message, response } = error
  const errorData = response?.data || {}
  const errorKey = Object.keys(errorData)[0]

  const renderError = Array.isArray(errorData[errorKey])
    ? errorData[errorKey]?.[0]
    : errorData?.detail ||
      errorData[errorKey]?.details ||
      errorData[errorKey]?.nonFieldErrors?.[0] ||
      errorData[errorKey]?.[Object.keys(errorData[errorKey])[0]]

  return showNotification?.({
    title: 'Erro',
    message:
      renderError ||
      message ||
      'Houve um erro durante o cadastro deste cartão. Verfique os dados e tente novamente',
    color: 'red'
  })
}
