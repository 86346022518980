import {
  Avatar,
  Box,
  Button,
  Grid,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import cardValidator from 'card-validator'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormEvent, ReactNode, useEffect, useState } from 'react'

import { api, getUrlString, notificationHandler, Yup } from '@/lib/utils'
import {
  MaskedCreditCardNumberMultiple,
  MaskedCreditCardValidDate,
  MaskedTaxDocumentCPFCNPJ
} from '@/lib/utils/masks'
import { useRouterLoading } from '@/providers/RouterLoadingProvider'
import { PaymentMethodOption as PaymentMethodOptionType } from '@/types'

import * as CreditCardFormFields from './Fields'
import { cryptoToolkit, errorHandler } from './utils'

interface Props {
  children?: ReactNode
  values?: any | null
  type: string
  packageReference: string
  paymentReference?: string
  cardOptions: PaymentMethodOptionType[]
}

interface FormValues {
  number: string
  holder: string
  validThru: string
  cvc: string
  taxDocument: string
  installments?: number | string
  stored?: boolean
}

export default function Checkout({
  children,
  type,
  packageReference,
  paymentReference,
  cardOptions
}: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug, basketUid, callbackUrl: callbackUrlQuery } = router.query || {}
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { isLoading: isLoadingGlobal } = useRouterLoading()

  // States
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Constants
  const disabled = isSubmitting || !cardOptions?.length

  const callbackUrl = getUrlString(callbackUrlQuery)
  const redirectHrefHelper = () => {
    if (callbackUrl) return encodeURI(callbackUrl)
    return `/checkout/${basketUid}/payment`
  }
  const redirectHref = redirectHrefHelper()

  const errorMessages = {
    number: {
      required: 'Você precisa colocar o número que se encontra na frente de cartão',
      format: 'Digite um número de cartão válido'
    },
    holder: { required: 'Preencha com o nome exatamente como impresso no cartão' },
    validThru: {
      required: 'Informe a data de validade do cartão',
      format: 'Informe uma data válida de vencimento do seu cartão. Ex: MM/AA'
    },
    cvc: { required: 'Informe o código de segurança do cartão' },
    taxDocument: {
      required: 'Necessário informar o CPF/CNPJ do titular do cartão',
      format: 'Insira um documento válido para o CPF/CNPJ'
    },
    installments: { required: 'Selecione o número de parcelas' }
  }

  // Validation schema
  const schema = Yup.object().shape({
    number: Yup.string()
      .test('valid-credit-card', errorMessages.number.format, value => {
        if (!value) return false
        const inputCard = cardValidator.number(value?.replace(/\s/g, ''))
        return inputCard?.isValid
      })
      .required(errorMessages.number.required),
    holder: Yup.string()
      .matches(
        /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])+$/u,
        'Digite apenas letras'
      )
      .required(errorMessages.holder.required)
      .min(3)
      .max(65),
    validThru: Yup.string()
      .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, errorMessages.validThru.format)
      .test('valid-expiry-date', errorMessages.validThru.format, value => {
        if (!value) return false
        const [expMonth, expYear] = value.split('/')
        const today = new Date()
        const month = today.getMonth() + 1
        const year = today.getFullYear() % 100
        if (Number(expYear) < year) return false
        if (Number(expYear) === year && Number(expMonth) < month) return false
        return true
      })
      .required(errorMessages.validThru.required),
    cvc: Yup.string()
      .required(errorMessages.cvc.required)
      .min(3, errorMessages.cvc.required)
      .max(4, errorMessages.cvc.required),
    taxDocument: Yup.string()
      .matches(
        /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2})$|^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/,
        errorMessages.taxDocument.format
      )
      .required(errorMessages.taxDocument.required),
    installments: Yup.string().required(errorMessages.installments.required),
    stored: Yup.boolean()
  })

  // Mantine form
  const form = useForm<FormValues>({
    validate: yupResolver(schema),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      number: '',
      cvc: '',
      holder: '',
      validThru: '',
      taxDocument: '',
      installments: '',
      stored: true
    }
  })

  // Actions
  const renderInstallments = () => {
    const inputCard = cardValidator.number(form?.values?.number)
    const method = cardOptions?.find(
      card => card?.paymentMethod?.name === inputCard?.card?.niceType
    )
    return form.isValid('number') ? method?.installments || [] : []
  }

  const handleSubmit = async (newValues: FormValues) => {
    const [expMonth, expYear] = newValues.validThru.split('/')

    const cleanedValues = {
      holder: newValues.holder,
      expYear,
      expMonth,
      number: newValues.number.replace(/\s/g, ''),
      cvc: newValues.cvc
    }

    setIsSubmitting(true)

    const inputCard = cardValidator.number(cleanedValues.number)
    const option = cardOptions?.find(
      card => card?.paymentMethod?.name === inputCard?.card?.niceType
    )

    try {
      const encrypted = await cryptoToolkit(cleanedValues, option)
      const { pagseguro, iugu, creditCard } = encrypted || {}

      await api.patch(
        `/${siteSlug}/checkout/${basketUid}/packages/${packageReference}/payment-methods/`,
        {
          paymentMethodUid: option?.paymentMethod?.uid,
          creditCard: {
            holder: newValues.holder,
            firstDigits: creditCard?.number.slice(0, 6),
            lastDigits: creditCard?.number.slice(-4),
            expMonth: creditCard?.expMonth,
            expYear: creditCard?.expYear,
            brand: option?.paymentMethod?.brand?.code,
            stored: newValues.stored,
            taxDocument: newValues.taxDocument,
            options: {
              ...(pagseguro?.hash ? { pagseguro } : {}),
              ...(iugu?.hash ? { iugu } : {})
            }
          },
          installments: type === 'order' ? newValues.installments : 1,
          ...(paymentReference ? { paymentReference } : {})
        }
      )
      notificationHandler({ variant: 'success', message: 'Cartão adicionado com sucesso' })
      return router.push(redirectHref)
    } catch (error: any) {
      errorHandler(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  // Effects
  useEffect(() => {
    if (!!type && type !== 'order') form.setFieldValue('installments', 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (!!type && type === 'order') form.setFieldValue('installments', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.number])

  useEffect(() => {
    if (!callbackUrl) router.prefetch(redirectHref)
  }, [router, redirectHref, callbackUrl])

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={12} xs={children ? 7 : 12}>
          <Stack>
            <Box>
              <Text size="sm" color="dimmed">
                Você pode pagar com:
              </Text>
              <Group spacing="sm" mt="xs">
                {cardOptions?.map(option => (
                  <Tooltip
                    key={option?.paymentMethod?.uid}
                    label={option?.paymentMethod?.brand?.name}
                    withArrow>
                    <Avatar
                      size={isXs ? 'sm' : 'md'}
                      src={option?.paymentMethod?.brand?.logo}
                      alt={option?.paymentMethod?.brand?.name}
                    />
                  </Tooltip>
                ))}
              </Group>
            </Box>
            <CreditCardFormFields.NumberField
              inputProps={{
                ...form.getInputProps('number'),
                component: MaskedCreditCardNumberMultiple,
                disabled
              }}
              cardOptions={cardOptions}
              form={form}
              errorMessage={errorMessages.number.format}
            />
            <CreditCardFormFields.NameField
              inputProps={{
                ...form.getInputProps('holder'),
                disabled,
                onBlur: ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
                  form.setFieldValue('holder', value.replace(/[^\S\r\n]+/g, ' ').trim())
                }
              }}
            />
            <Group spacing="sm" noWrap align="flex-start" grow>
              <CreditCardFormFields.ValidThruField
                inputProps={{
                  ...form.getInputProps('validThru'),
                  component: MaskedCreditCardValidDate,
                  disabled
                }}
              />
              <CreditCardFormFields.CVVField
                inputProps={{ ...form.getInputProps('cvc'), disabled }}
              />
            </Group>
            <CreditCardFormFields.TaxDocumentField
              inputProps={{
                ...form.getInputProps('taxDocument'),
                component: MaskedTaxDocumentCPFCNPJ,
                disabled
              }}
            />
            {type === 'order' && !!form.isValid('number') && (
              <CreditCardFormFields.InstallmentsSelectField
                selected={form.values.installments}
                installments={renderInstallments()}
                inputProps={{
                  ...form.getInputProps('installments'),
                  disabled: disabled || !form.isValid('number')
                }}
              />
            )}
            <CreditCardFormFields.StoredCheckbox
              inputProps={{
                ...form.getInputProps('stored', { type: 'checkbox' }),
                disabled
              }}
            />
            <Group position="apart" spacing="md" my="lg">
              <Button
                size={isXs ? 'sm' : 'md'}
                fullWidth={!!isXs}
                variant="outline"
                disabled={isSubmitting || isLoadingGlobal}
                component={Link}
                href={redirectHref}>
                Voltar
              </Button>
              <Button
                type="submit"
                size={isXs ? 'sm' : 'md'}
                fullWidth={!!isXs}
                loading={isSubmitting}
                disabled={!form.isValid() || !form.isDirty() || disabled || isLoadingGlobal}>
                Continuar
              </Button>
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span={12} xs={5} hidden={!children}>
          {children}
        </Grid.Col>
      </Grid>
    </form>
  )
}
