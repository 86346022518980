import { Alert, Button, Group, Modal, Text, TextInput, Title, useMantineTheme } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { IconAlertCircle, IconCircleCheck, IconUser } from '@tabler/icons'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { api, setSession } from '@/lib/utils'

import { LoginForm, SignupForm } from '..'

interface Props {
  opened: boolean
  setOpened: any
}

export default function LoginSignupModalForm({ opened, setOpened }: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  const { site: siteSlug } = router.query || {}

  // States
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showLogin, setShowLogin] = useState<boolean | null>(null)
  const form = useForm({
    initialValues: { email: '' },
    validate: { email: val => (/^\S+@\S+\.\S+$/.test(val) ? null : 'E-mail inválido') }
  })

  // Actions
  const handleSubmit = async () => {
    setLoading(true)
    setMessage('')
    setError('')

    try {
      const response = await api.post(`/${siteSlug}/accounts/check-email/`, {
        email: form.values.email
      })
      if (response?.data?.flow === 'login') {
        setShowLogin(true)
        setSession('modalAction', 'login')
      } else if (response?.data?.flow === 'signup') {
        setShowLogin(false)
        setSession('modalAction', 'signup')
      } else if (response?.data?.flow === 'inactive') {
        setShowLogin(null)
        setError(
          'Usuário se encontra inativo. Entre em contato com o suporte do site caso queira reativar a conta ou aceese com outra conta.'
        )
      } else {
        setShowLogin(null)
        setError('Ocorreu um erro ao verificar o e-mail. Tente novamente mais tarde.')
      }
    } catch (error: any) {
      const { response } = error
      setError(response?.data?.detail)
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <Modal
      centered
      opened={opened}
      size="lg"
      overflow="inside"
      onClose={() => setOpened(false)}
      title={
        <Title order={isXs ? 6 : 4} sx={{ display: 'flex', alignItems: 'center' }}>
          <IconUser />
          {showLogin || showLogin === null ? 'Login' : 'Cadastro'}
        </Title>
      }>
      <Alert
        icon={<IconCircleCheck size={16} />}
        title="Sucesso!"
        color="green"
        mb="md"
        hidden={!message}
        withCloseButton
        onClose={() => setMessage('')}>
        <Text m={0}>{message}</Text>
      </Alert>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Ops! Algo deu errado."
        color="red"
        mb="md"
        hidden={!error}
        withCloseButton
        onClose={() => setError('')}>
        <Text dangerouslySetInnerHTML={{ __html: error }} m={0} />
      </Alert>

      {showLogin !== null ? (
        showLogin ? (
          <LoginForm.LoginModal email={form.values.email} onBack={() => setShowLogin(null)} />
        ) : (
          <SignupForm.SignupModal email={form.values.email} onBack={() => setShowLogin(null)} />
        )
      ) : (
        showLogin === null && (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Text mb="xs">Você precisa estar logado para adicionar o produto no carrinho</Text>

            <TextInput
              required
              placeholder="E-mail"
              value={form.values.email}
              onChange={event => form.setFieldValue('email', event.currentTarget.value.trim())}
              error={form.errors.email}
            />

            <Group position="right" mt="xl" mb="xs">
              <Button type="submit" loading={loading}>
                Próximo
              </Button>
            </Group>
          </form>
        )
      )}
    </Modal>
  )
}
